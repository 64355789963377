import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getSurveyStandardDeviation(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSurveyStandardDeviation/${filter.event.id}`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPurchaseInformation(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('purchaseInformation')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('user', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    workshopList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('workshopList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    quoteList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('quoteList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    cpatPdf(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('cpatPdf', data, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    cpatSinglePdf(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('cpatSinglePdf', data, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    mailSend(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('mailSend', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    dropdownWorkshopList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('dropdownWorkshopList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    dropdownPresenterList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('dropdownPresenterList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    detailWorkshop(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`detailWorkshop/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('settings', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateSetting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateSetting/1`, data)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchCustomerHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers", { params: queryParams })
          .get('customer_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchCustomerHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`customer_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchCustomerColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('customer_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchCustomerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('customer_headers/customers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //INVENTORY SETTINGS

    fetchInventoryHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventory_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchInventoryHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`inventory_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInventoryColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('inventory_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchInventoryList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('inventory_headers/inventory', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //SALE SETTINGS

    fetchSaleHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('sale_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchSaleHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`sale_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchSaleColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('sale_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchSaleList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('sale_headers_column', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //PURCHASE SETTINGS

    fetchPurchaseHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchase_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchPurchaseHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`purchase_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchPurchaseColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('purchase_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchPurchaseList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('purchase_headers_column', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //SALE ORDER SETTINGS

    fetchSaleOrderHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('saleorder_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchSaleOrderHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`saleorder_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchSaleOrderColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('saleorder_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchSaleOrderList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('saleorder/orders', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    /// PURCHASE ORDER SETTINGS

    fetchPurchaseOrderHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchaseorder_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchPurchaseOrderHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`purchaseorder_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchPurchaseOrderColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchaseorder_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchPurchaseOrderList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchaseorder/orders', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    /********* Roles */
    /*********** */
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('roles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchPermissions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    addRole(ctx, role) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('roles/store', role)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateRole(payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`roles/${payload.role}`, payload.permissions)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    addNewGroupItem(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveGroup', payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateGroupItem(ctx, group) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateGroup/${group.id}`, group)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteGroupItem(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deleteGroup/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addNewQuestionItem(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveQuestion', payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    editQuestion(ctx, question) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`editQuestion/${question.id}`, question)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    editGroup(ctx, group) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`editSurveyGroupName/${group.id}`, group)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteQuestion(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deleteQuestion/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchGroups(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('listGroup', { id: id })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchSurvey(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`survey/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    timelineSurveyList(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`timelineSurveyList/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchAllGroupsData(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('allGroups')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addNewSurvey(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveResult', payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    editSurvey(ctx, survey) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`editResult/${survey.id}`, survey)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    completeSurvey(ctx, survey) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`completeSurvey/${survey.id}`, survey)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAllSurveyResults(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listResult', {})
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    getLastEventItem(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getLastResult', {})
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    getSurveysWithId(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getResultsWithEventId/${filter.event.id}`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getSurveysObsWithId(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getResultsObsWithEventId/${filter.event.id}`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Survey Based Stat
    getSurveyBasedResults(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getSurveyBasedResults`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFilterSurveyDate(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterSurveyDate`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Group Based Stat
    getGroupBasedStats(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getGroupBasedStats`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //school Based Stats
    getSchoolDistributionResults(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getSchoolDistributionResults`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFilterSchoolDistributionDate(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterSchoolDistributionDate`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCTResultsEventId(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCTResultsEventId/${filter.event.id}`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCTResultsGruopId(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCTResultsEventId/${filter.event.id}`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCTResultsByTeacher(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCTResultsEventId/${filter.event.id}`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getSurveysWithIdObserver(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getResultsWithObserverEventId/${filter.event.id}`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Grade Level
    addNewGradeLevelItem(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveGradeLevel', payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchGradeLevels(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getGradeLevels')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchsummaryList(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`summaryList/${id}`)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    summaryUpdate(ctx, gl) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`summaryUpdate/${gl.id}`, gl)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateGradeLevelItem(ctx, gl) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateGradeLevel/${gl.id}`, gl)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteGradeLevel(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deleteGradeLevel/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Questionnaire settings
    createNewSurvey(ctx, questionnaireName) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('createQuestionnaire', questionnaireName)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateSurvey(ctx, questionnaire) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateQuestionnaire/${questionnaire.id}`, questionnaire)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateQuestionnaireColor(ctx, questionnaire) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateQuestionnaireColor/${questionnaire.id}`, questionnaire)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchQuestionnaires(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getQuestionnaires')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchQuestionnaireDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getQuestionnaireDetail/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    controlQuestionResultTable(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('controlQuestionAndResultInTable', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    copyQuestionnaire(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('copyQuestionnaire', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    controlGroupResultTable(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('controlGroupResultInTable', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addQuestionResultTable(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('addQuestionResultTable', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteQuestionResultTable(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('deleteQuestionResultTable', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateQuestionResultTable(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateQuestionResultTable', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateGroupResultTable(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateGroupResultTable', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    copyNewQuestionnaireForDelete(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('copyNewQuestionnaireForDelete', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    copyNewQuestionnaireForAdd(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('copyNewQuestionnaireForAdd', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    copyNewQuestionnaireForUpdate(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('copyNewQuestionnaireForUpdate', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    copyNewQuestionnaireForGroupUpdate(ctx, obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('copyNewQuestionnaireForGroupUpdate', obj)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // growth profile
    getAllSurveyResults(ctx, filterDate) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('listResult', filterDate)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    storeVideo(ctx, data) {
      return new Promise((resolve, reject) => {
        const config = {
          header: {
            'Content-Type': 'multipart/form-data',
          },
        };

        axiosIns
          .post('storeVideo', data, config)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTool(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getTool`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getResource(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getResource`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
